'use client'

import { useSearchStore } from '@stores/search.store'

import type { Country, JobSearch, PopularSearch } from '@types'

import { match } from 'ts-pattern'

import Link from '@components/link'
import useLocale from '@hooks/client/useLocale'
import useUpdatePreferences from '@hooks/user/useUpdatePreferences'
import { seoUrlPathEncode } from '@lib/helpers/client'
import getLocationString from '@lib/helpers/cross-env/get-locations-string'

import styles from './popular-job-item.module.scss'

type PopularJobItemProps = {
  data: PopularSearch
  removeLocation?: boolean
  type: 'wide' | 'compact'
}

const getUrl = (
  removeLocation: boolean,
  popuparSearch: PopularSearch,
  country: Country,
  jobSearch?: JobSearch,
): string => {
  if (removeLocation || !jobSearch?.location?.name) {
    return `/jobs/q-${seoUrlPathEncode(popuparSearch.microRoles, 'role')}-l-all-locations`
  }
  const cityName = jobSearch?.location?.name || popuparSearch.geoLocation?.name
  const secondParam = match(country)
    .with('us', () => jobSearch?.location?.state || popuparSearch.geoLocation?.state)
    .with('uk', () => jobSearch?.location?.county || popuparSearch.geoLocation?.county)
    .exhaustive() as string

  return `/jobs/q-${seoUrlPathEncode(popuparSearch.microRoles, 'role')}-l-${getLocationString(cityName, secondParam)}`
}

export default function PopularJobItem({ data, removeLocation, type }: PopularJobItemProps): JSX.Element {
  const nextSearch = useSearchStore((state) => state.nextSearch)
  const resetSearch = useSearchStore((state) => state.resetSearch)
  const updatePreferences = useUpdatePreferences()
  const country = useLocale()

  if (data?.microRoles === '"') {
    return <></>
  }

  const submitSearch = (): void => {
    resetSearch()
    updatePreferences(undefined, {
      macro: data.microRoles,
    })
  }

  return (
    <Link
      href={getUrl(removeLocation || false, data, country, nextSearch)}
      className={`${styles[`search-option-${type}`]} ${styles.common}`}
      data-pw="popular-job"
      onClick={submitSearch}
    >
      {`${data?.microRoles}${
        type === 'wide' && !removeLocation ? `, ${nextSearch?.location?.name || data.geoLocation?.name}` : ''
      }`}
    </Link>
  )
}
